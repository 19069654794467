<template>
  <!-- 监控 --- 国标id授权 -->
  <div style="margin-top: 20px;">
      <!-- 查询表单 -->
      <el-form
          :inline="true"
          size="small"
          label-position="right"
          class="query_form"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="集团：">
            <el-input
              maxlength="15"
              @keyup.enter.native="onSubmit"
              placeholder="请输入集团名称"
              :value="queryForm.group_name"
              @input="(e) => (queryForm.group_name = validSpace(e))"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <lbButton icon="chaxun" :fill="true" @click="onSubmit()"
              >查询</lbButton
            >
          </el-form-item>
        </el-form>
    <div class="box-card">
      <le-card title="国标ID授权">
        <template slot="heard-right">
          <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel">删除</lbButton>
          <lbButton icon="xinzeng" @click="addNewItem">新增</lbButton>
        </div>
        </template>
            <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
             <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </avue-crud> -->
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
            header-align="center"
          ></el-table-column>
          <el-table-column prop="auth_code" label="授权码"></el-table-column>
          <el-table-column prop="group_name" label="集团"></el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      </le-card>
    </div>
    <!-- 新增或编辑授权码 -->
    <lebo-dialog
      append-to-body
      :title="addForm.Id ? '编辑' : '新增'"
      :isShow="isAddDialog"
      width="30%"
      @close="closeAddDialog" footerSlot
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="集团名称：" prop="group_id">
          <el-select
            popper-class="my-select"
            :disabled="addForm.Id ? true : false"
            style="width: 100%"
            v-model="addForm.group_id"
            placeholder="请选择集团"
            @change="selectChange"
          >
            <el-option
              v-for="item in groupSelectList"
              :key="item.group_id"
              :label="item.group_name"
              :value="item.group_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="授权码：" prop="auth_code">
          <el-input
            maxlength="2"
            v-model.trim="addForm.auth_code"
            placeholder="请输入授权码"
            @input="(e) => (addForm.auth_code = validNumber(e))"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isAddDialog = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="addAndEdit" v-preventReClick
          >保 存</lbButton
        >
      </span>
    </lebo-dialog>
  </div>
</template>

<script>
import {
  gBAuthorizedGroupListParam,
  gBAuthorizedGroupList,
  gBAuthorizedAdd,
  gBAuthorizedEdit,
  deleteGBAuthorized
} from '@/api/monitoring'
export default {
  data () {
    return {
      queryForm: {
        PageIndex: 1,
        PageSize: 10,
        group_name: ''
      },
      total: 0,
      tableData: [],
      multipleSelection: [],
      // 是否展示新增或编辑对话框
      isAddDialog: false,
      // 新增
      addForm: {
        Id: '',
        group_id: '', // 集团id
        auth_code: '', // 授权码
        group_name: '' // 集团名称
      },
      addFormRules: {
        group_id: [{ required: true, message: '请选择集团', trigger: 'blur' }], // 集团id
        auth_code: [
          { required: true, message: '请输入授权码', trigger: 'blur' }
        ] // 授权码
      },
      // 集团下拉列
      groupSelectList: [],
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: false,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '授权码',
            prop: 'auth_code'
          }, {
            label: '集团',
            prop: 'group_name'
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    console.log('created-----', this)
    this.fnGetGBAuthorizedList()
  },
  methods: {
    // 获取国标ID授权列表
    async fnGetGBAuthorizedList () {
      const res = await gBAuthorizedGroupListParam(this.queryForm)
      // console.log('获取国标ID授权列表*-----', res);
      this.tableData = res.Code === 200 && res.Data ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      // console.log(this.queryForm);
      this.fnGetGBAuthorizedList()
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetGBAuthorizedList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetGBAuthorizedList()
    // },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fnGetGBAuthorizedList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetGBAuthorizedList()
    // },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // 删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的授权码, 是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.fnDelItem(this.multipleSelection)
          })
          .catch(() => {
            this.$msg.info('已取消删除！')
          })
      } else {
        this.$msg.warning('请选择要删除的授权码')
      }
    },
    // 删除请求
    async fnDelItem (ids) {
      const res = await deleteGBAuthorized(ids)
      console.log(res)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetGBAuthorizedList()
    },
    // 新增
    addNewItem () {
      this.groupSelectList = []
      this.fnGetGroupSelectList()
      this.isAddDialog = true
    },
    // 获取集团下拉列
    async fnGetGroupSelectList () {
      const res = await gBAuthorizedGroupList()
      // console.log('获取集团下拉列----', res);
      if (res && res.Code === 200 && res.Data) {
        this.groupSelectList = this.groupSelectList.concat(res.Data)
      } else {
        this.groupSelectList = []
      }
    },
    // 下拉列发生改变时触发
    selectChange (id) {
      // console.log(id);
      var index = this.groupSelectList.findIndex(
        (item) => item.group_id === id
      )
      this.addForm.group_name = this.groupSelectList[index].group_name
    },
    // 编辑
    edit (item) {
      // console.log(item);
      this.groupSelectList = []
      this.groupSelectList.push({
        group_id: item.group_id,
        group_name: item.group_name
      })
      this.fnGetGroupSelectList()
      this.isAddDialog = true
      var that = this
      this.$nextTick(() => {
        that.addForm.group_id = item.group_id
        that.addForm.group_name = item.group_name
        that.addForm.Id = item.Id
        that.addForm.auth_code = item.auth_code
      })
    },
    // 确定
    addAndEdit () {
      // 验证表单
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          // console.log(this.addForm);
          if (this.addForm.Id) {
            this.fnEditGBAuthorized()
          } else {
            this.fnAddGBAuthorized()
          }
        } else {
          // console.log('error submit!!');
          return false
        }
      })
    },
    // 新增请求
    async fnAddGBAuthorized () {
      const res = await gBAuthorizedAdd(this.addForm)
      if (res && res.Code === 200) {
        this.isAddDialog = false
        this.onSubmit()
      }
    },
    // 编辑请求
    async fnEditGBAuthorized () {
      const res = await gBAuthorizedEdit(this.addForm)
      if (res && res.Code === 200) {
        this.isAddDialog = false
        this.fnGetGBAuthorizedList()
      }
    },
    // 关闭对话框时触发
    closeAddDialog () {
      this.isAddDialog = false
      this.$refs.addFormRef.resetFields()
      this.addForm.group_name = ''
      this.addForm.Id = ''
      // console.log(this.addForm);
    }
  }
}
</script>

<style lang="less" scoped>
</style>
